var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "page-filters-section",
        { attrs: { "top-img": "/img/redeem/staff_header.png" } },
        [
          _c("h1", { staticClass: "text-center" }, [_vm._v("Redeem Code")]),
          _c("span", [
            _vm._v("Here you can redeem your promo code for valuable rewards"),
          ]),
          _c("br"),
          _c("br"),
        ]
      ),
      _c(
        "v-col",
        { staticClass: "page-content" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "text-center hidden-sm-and-down",
                  attrs: { cols: "5" },
                },
                [
                  _c("img", {
                    staticClass: "hero-image ma-auto",
                    attrs: {
                      alt: "Wizard claiming",
                      src: "/img/redeem/revolution_wizard.png",
                    },
                  }),
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "7" } },
                [
                  _c(
                    "v-stepper",
                    {
                      attrs: { "max-width": "750px", vertical: "" },
                      model: {
                        value: _vm.step,
                        callback: function ($$v) {
                          _vm.step = $$v
                        },
                        expression: "step",
                      },
                    },
                    [
                      _c(
                        "v-stepper-header",
                        { staticClass: "d-flex align-center justify-start" },
                        [
                          _c("h4", { staticClass: "ml-8" }, [
                            _vm._v(" Claim Reward "),
                          ]),
                        ]
                      ),
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.step > 1, step: "1" } },
                        [_vm._v(" Connect Wallet ")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "1" } },
                        [
                          _c(
                            "v-card",
                            [
                              !_vm.isWalletConnected
                                ? _c(
                                    "v-row",
                                    [
                                      !_vm.isWalletConnected
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex align-center justify-center",
                                              attrs: { cols: "12" },
                                            },
                                            [_c("WalletButton")],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm.connectError.length === 0
                                ? _c(
                                    "v-row",
                                    { staticClass: "pa-4 pb-0 ma-2" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: { text: "", type: "info" },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Processing"),
                                              ]),
                                              _c("v-progress-linear", {
                                                attrs: {
                                                  "background-color": "blue",
                                                  indeterminate: "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-row",
                                    { staticClass: "pa-4 pb-0 ma-2" },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                text: "",
                                                type: "error",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            this.connectError
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "d-flex align-end justify-end",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "error",
                                                          },
                                                          on: {
                                                            click: _vm.init,
                                                          },
                                                        },
                                                        [_vm._v(" Retry ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.step > 2, step: "2" } },
                        [_vm._v(" Enter Code ")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "2" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                { staticClass: "pa-4 ma-2" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "7" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          clearable: "",
                                          color: "golden",
                                          dense: "",
                                          "hide-details": "",
                                          placeholder: "YOUR CODE",
                                          solo: "",
                                        },
                                        on: {
                                          change: (value) =>
                                            (this.code = (
                                              value || ""
                                            ).toUpperCase()),
                                        },
                                        model: {
                                          value: _vm.code,
                                          callback: function ($$v) {
                                            _vm.code = $$v
                                          },
                                          expression: "code",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "5" } },
                                    [
                                      _c(
                                        "main-button",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.codeConfirmButtonDisabled,
                                            loading:
                                              _vm.codeConfirmButtonLoading,
                                            "on-click": _vm.signRedeemRequest,
                                          },
                                        },
                                        [_vm._v(" OK ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.step > 3, step: "3" } },
                        [_vm._v(" Sign Redeem Request ")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "3" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                { staticClass: "pa-4 pb-0 ma-2" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      !_vm.nonce
                                        ? _c(
                                            "v-alert",
                                            {
                                              attrs: { text: "", type: "info" },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Processing"),
                                              ]),
                                              _c("v-progress-linear", {
                                                attrs: {
                                                  "background-color": "blue",
                                                  indeterminate: "",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.nonce
                                        ? _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                text: "",
                                                type: "warning",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Waiting for confirmation"
                                                ),
                                              ]),
                                              _c("v-progress-linear", {
                                                attrs: {
                                                  "background-color": "yellow",
                                                  indeterminate: "",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.step > 4, step: "4" } },
                        [_vm._v(" Wait For Confirmation ")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "4" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  !_vm.confirmationError
                                    ? _c(
                                        "v-alert",
                                        { attrs: { text: "", type: "info" } },
                                        [
                                          _c("span", [_vm._v("Processing")]),
                                          _c("v-progress-linear", {
                                            attrs: {
                                              "background-color": "blue",
                                              indeterminate: "",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.confirmationError
                                    ? _c(
                                        "v-alert",
                                        { attrs: { text: "", type: "error" } },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      _vm._s(
                                                        this.confirmationError
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "d-flex align-end justify-end",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { color: "error" },
                                                      on: { click: _vm.init },
                                                    },
                                                    [_vm._v(" Reset ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.step > 5, step: "5" } },
                        [_vm._v(" Have fun ")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "5" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          src: "/img/redeem/chest-opened.png",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8" } },
                                    [
                                      _c("v-card-title", [
                                        _vm._v(" Congratulations "),
                                      ]),
                                      _c("v-card-text", [
                                        _vm._v(
                                          " Reward will be transferred to you soon. "
                                        ),
                                      ]),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c(
                                            "v-btn",
                                            { on: { click: _vm.init } },
                                            [_vm._v(" Next Code ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "MyWizards",
                                                  params: {},
                                                },
                                              },
                                            },
                                            [_vm._v(" My Wizards ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-header",
                        { staticClass: "d-flex align-center justify-start" },
                        [
                          _c(
                            "main-button",
                            {
                              staticClass: "mx-8 mt-4",
                              attrs: {
                                "on-click": _vm.openHomepage,
                                glow: true,
                              },
                            },
                            [_vm._v(" Download Game ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import {apiServiceVue} from "@/services/apiService.vue";

export default class {

    /** @type {string} */
    #url = process.env.VUE_APP_REDEEM_URL;

    constructor() {
    }

    /**
     *
     * @param walletAddress
     * @returns {Promise<String>}
     */
    async fetchRedeemNonce(walletAddress) {
        const response = await apiServiceVue.get(`${this.#url}${walletAddress}/nonce/`);
        return response.data.nonce;
    }

    /**
     *
     * @param {string} walletAddress
     * @param {string} signedOnce
     * @param {string} code
     * @returns {Promise<void>}
     */
    async redeemCodeReward(walletAddress, signedOnce, code) {
        return await apiServiceVue.post(`${this.#url}${walletAddress}/claim/`, {
            code: code,
            signedOnce: signedOnce,
        });
    }


};

import OnChainRequestAbstract from "@/services/contracts/onChainRequestAbstract";
import WalletIntegration from "@/wallet-integration";

export default class SignService extends OnChainRequestAbstract {
    //Introduce EIP712 (nice message during signing)-check with WalletConnect
    //https://ethereum.stackexchange.com/questions/74027/eip712-implementation-for-web3-1-0
    //https://medium.com/metamask/eip712-is-coming-what-to-expect-and-how-to-use-it-bb92fd1a7a26
    //https://docs.metamask.io/wallet/reference/personal_sign/
    async signText(text) {
        text = '0x' + Buffer.from(text, 'utf8').toString('hex'); //UTF8 to hex for MetaMask to display
        return await WalletIntegration.personalSign(text)
            .then((signedText) => {
                return signedText;
            })
            .catch((error) => {
                console.debug('error', error);
                this.handleTxError(error);
            });
    }
}

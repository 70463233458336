<template>
    <v-container>
        <page-filters-section top-img="/img/redeem/staff_header.png">
            <h1 class="text-center">Redeem Code</h1>
            <span>Here you can redeem your promo code for valuable rewards</span>
            <br/><br/>
        </page-filters-section>

        <v-col class="page-content">
            <v-row>
                <v-col class="text-center hidden-sm-and-down" cols="5">
                    <img alt="Wizard claiming" class="hero-image ma-auto" src="/img/redeem/revolution_wizard.png"/>
                </v-col>
                <v-col cols="12" md="7">
                    <v-stepper
                        v-model="step"
                        max-width="750px"
                        vertical
                    >
                        <v-stepper-header class="d-flex align-center justify-start">
                            <h4 class="ml-8">
                                Claim Reward
                            </h4>
                        </v-stepper-header>
                        <v-stepper-step
                            :complete="step > 1"
                            step="1"
                        >
                            Connect Wallet
                        </v-stepper-step>
                        <v-stepper-content step="1">
                            <v-card>
                                <v-row v-if="!isWalletConnected">
                                    <v-col v-if="!isWalletConnected" class="d-flex align-center justify-center"
                                           cols="12">
                                        <WalletButton></WalletButton>
                                    </v-col>
                                </v-row>
                                <v-row v-else-if="connectError.length === 0" class="pa-4 pb-0 ma-2">
                                    <v-col cols="12">
                                        <v-alert
                                            text
                                            type="info"
                                        >
                                            <span>Processing</span>
                                            <v-progress-linear background-color="blue"
                                                               indeterminate
                                            >
                                            </v-progress-linear>
                                        </v-alert>
                                    </v-col>
                                </v-row>
                                <v-row v-else class="pa-4 pb-0 ma-2">
                                    <v-col cols="12">
                                        <v-alert
                                            text
                                            type="error"
                                        >
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <p>{{ this.connectError }}</p>
                                                </v-col>
                                                <v-col class="d-flex align-end justify-end" cols="12">
                                                    <v-btn color="error"
                                                           @click="init"
                                                    >
                                                        Retry
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-stepper-content>
                        <v-stepper-step
                            :complete="step > 2"
                            step="2"
                        >
                            Enter Code
                        </v-stepper-step>
                        <v-stepper-content step="2">
                            <v-card>
                                <v-row class="pa-4 ma-2">
                                    <v-col cols="7">
                                        <v-text-field
                                            v-model="code"
                                            clearable
                                            color="golden"
                                            dense
                                            hide-details
                                            placeholder="YOUR CODE"
                                            solo
                                            @change="(value) => this.code = (value || '').toUpperCase()"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="5">
                                        <main-button
                                            :disabled="codeConfirmButtonDisabled"
                                            :loading="codeConfirmButtonLoading"
                                            :on-click="signRedeemRequest"
                                        >
                                            OK
                                        </main-button>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-stepper-content>
                        <v-stepper-step
                            :complete="step > 3"
                            step="3"
                        >
                            Sign Redeem Request
                        </v-stepper-step>
                        <v-stepper-content step="3">
                            <v-card>
                                <v-row class="pa-4 pb-0 ma-2">
                                    <v-col cols="12"
                                    >
                                        <v-alert
                                            v-if="!nonce"
                                            text
                                            type="info"
                                        >
                                            <span>Processing</span>
                                            <v-progress-linear background-color="blue"
                                                               indeterminate
                                            >
                                            </v-progress-linear>
                                        </v-alert>
                                        <v-alert
                                            v-if="nonce"
                                            text
                                            type="warning"
                                        >
                                            <span>Waiting for confirmation</span>
                                            <v-progress-linear
                                                background-color="yellow"
                                                indeterminate
                                            >
                                            </v-progress-linear>
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-stepper-content>
                        <v-stepper-step
                            :complete="step > 4"
                            step="4"
                        >
                            Wait For Confirmation
                        </v-stepper-step>
                        <v-stepper-content step="4">
                            <v-card>
                                <v-col cols="12"
                                >
                                    <v-alert
                                        v-if="!confirmationError"
                                        text
                                        type="info"
                                    >
                                        <span>Processing</span>
                                        <v-progress-linear background-color="blue"
                                                           indeterminate
                                        >
                                        </v-progress-linear>
                                    </v-alert>
                                    <v-alert
                                        v-if="confirmationError"
                                        text
                                        type="error"
                                    >
                                        <v-row no-gutters>
                                            <v-col cols="12">
                                                <p>{{ this.confirmationError }}</p>
                                            </v-col>
                                            <v-col class="d-flex align-end justify-end" cols="12">
                                                <v-btn color="error"
                                                       @click="init"
                                                >
                                                    Reset
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-alert>
                                </v-col>
                            </v-card>
                        </v-stepper-content>
                        <v-stepper-step
                            :complete="step > 5"
                            step="5"
                        >
                            Have fun
                        </v-stepper-step>
                        <v-stepper-content step="5">
                            <v-card>
                                <v-row>
                                    <v-col cols="4">
                                        <v-img src="/img/redeem/chest-opened.png"/>
                                    </v-col>
                                    <v-col cols="8">
                                        <v-card-title>
                                            Congratulations
                                        </v-card-title>
                                        <v-card-text>
                                            Reward will be transferred to you soon.
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-btn @click="init">
                                                Next Code
                                            </v-btn>
                                            <v-btn
                                                :to="{ name: 'MyWizards', params: {}}"
                                            >
                                                My Wizards
                                            </v-btn>
                                        </v-card-actions>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-stepper-content>
                        <v-divider></v-divider>
                        <v-stepper-header class="d-flex align-center justify-start">
                            <main-button :on-click="openHomepage"
                                         :glow="true"
                                         class="mx-8 mt-4"
                            >
                                Download Game
                            </main-button>
                        </v-stepper-header>
                    </v-stepper>
                </v-col>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
import DialogBox from "../components/dialog-box";
import PageFiltersSection from "../components/page-filters-section";
import WalletButton from "@/components/walletButton";
import MainButton from "@/components/ui/main-button";
import RedeemCodeService from "@/services/redeemCodeService";
import SignService from "@/services/contracts/signService";
import AccountService from "@/services/accountService";

export default {
    components: {MainButton, WalletButton, PageFiltersSection, DialogBox},

    data() {
        return {
            /** @type {RedeemCodeService} */
            redeemService: undefined,
            /** @type {SignService} */
            signService: undefined,
            /** @type {AccountService} */
            accountService: undefined,
            step: 1,
            code: '',
            nonce: '',
            signedOnce: '',
            connectError: '',
            confirmationError: '',
        };
    },

    mounted: function () {
        this.redeemService = new RedeemCodeService();
        this.accountService = new AccountService();

        if (this.isWalletConnected) {
            this.init();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.init();
            });
        }
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        walletAddress() {
            return this.web3?.address?.toLowerCase();
        },
        codeConfirmButtonLoading() {
            return this.step > 2;
        },
        codeConfirmButtonDisabled() {
            return this.code.length < 3;
        },
    },
    methods: {
        async init() {
            this.step = 1;

            this.signService = new SignService();
            this.code = '';
            this.signedOnce = '';
            this.confirmationError = '';

            await this.ensureAccountCreated()
        },
        async ensureAccountCreated() {
            try {
                const account = await this.accountService.fetch(this.walletAddress);
                this.step = 2;
            } catch (e) {
                this.connectError = 'Failed to fetch account ' + e.message
            }
        },
        async signRedeemRequest() {
            this.step = 3;
            try {
                this.nonce = await this.redeemService.fetchRedeemNonce(this.walletAddress);
                this.signedOnce = await this.signService.signText(this.nonce);
                await this.redeemCode();
            } catch (e) {
                this.flashMessage.show({
                    status: 'error',
                    message: 'Sign error'
                });
                this.step = 2;
            }
        },
        async redeemCode() {
            this.step = 4;
            this.redeemService.redeemCodeReward(this.walletAddress, this.signedOnce, this.code)
                .then((_) => {
                    this.step = 5;
                })
                .catch((e) => {
                    this.confirmationError = `${e.response.data.error}.`
                    }
                )
        },
        openHomepage() {
            window.open('https://www.wizarre.io/', '_blank', 'noreferrer');
        }
    }
};

</script>

<style lang="scss" scoped>
.page-content {
    img.hero-image {
        width: 80%;
        max-width: 475px;
        height: auto;
    }

    .theme--dark.v-stepper {
        background: var(--v-primary-darken1);
    }

}

</style>
